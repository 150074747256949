import Vue from 'vue'
import App from './App.vue'
import Buefy from 'buefy'

import router from './router.js'
import './icons.js'

import 'buefy/dist/buefy.css'
import './assets/css/sdf.css'
import './assets/css/style.css'

import * as VueGoogleMaps from 'vue2-google-maps'

import LightBox from './components/LightBox'
Vue.component('light-box', LightBox)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDrw9cTE4KH-vsD2Fkcf2BjnNoiFP2Y3Sw',
    libraries: 'places'
  }
})

Vue.config.productionTip = false
Vue.use(Buefy)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
