import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faTimes,
  faPhoneAlt,
  faMapMarkerAlt,
  faEnvelope
} from '@fortawesome/free-solid-svg-icons'

import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faFacebookSquare,
  faInstagramSquare,
  faLinkedin
} from '@fortawesome/free-brands-svg-icons'

library.add(
  faTimes,
  faPhoneAlt,
  faMapMarkerAlt,
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faEnvelope,
  faFacebookSquare,
  faInstagramSquare,
  faLinkedin
)

Vue.component('fa', FontAwesomeIcon)
