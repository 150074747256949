<template>
  <div id="app" :class="{ 'scrolled-down': scrolledDown }">

    <div class="nav-container">
      <div class="top-bar">
        <div class="content-container flex-vertical-middle">
          <div class="font-size-80 flex-1">
            <a href="tel:0260780863" class="phone margin-right-20"><fa icon="phone-alt" class="margin-right-5" />02 6078 0863</a>
            <router-link to="/contact" class="address"><fa icon="map-marker-alt" class="margin-right-5" />51 Union Road, North Albury, NSW 2640</router-link>
          </div>
          <div class="icons">
            <a class="margin-left-10" target="_blank" href="https://www.facebook.com/CFE-Projects-Maintenance-PTY-LTD-379913218738878/">
              <fa :icon="['fab', 'facebook-f']" />
            </a>
            <!-- <a class="margin-left-10" target="_blank" href="http://cfep.com.au/#">
              <fa :icon="['fab', 'instagram']" />
            </a> -->
            <a class="margin-left-10" target="_blank" href="https://www.linkedin.com/company/cfe-projects-and-maintenance/">
              <fa :icon="['fab', 'linkedin-in']" />
            </a>
            <a class="margin-left-10" target="_blank" href="mailto:enquiries@cfep.com.au">
              <fa :icon="['fas', 'envelope']" />
            </a>
          </div>
        </div>
      </div>
      <nav class="navbar content-container" role="navigation" aria-label="main navigation">
        <div class="navbar-brand" style="display: flex; align-items: center;">
          <a class="navbar-item padding-left-0" href="/" style="padding-left: 0;">
            <img src="./assets/img/logo-full-colour.png" class="logo">
          </a>

          <a
            role="button"
            class="navbar-burger"
            :class="{'is-active': burgersOn }"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
            @click="burgersOn = !burgersOn"
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>

        <div id="navbarBasicExample" class="navbar-menu" :class="{'is-active': burgersOn }">
          <!-- <div class="navbar-start">
          </div> -->

          <div class="navbar-end">
            <router-link class="navbar-item" to="/">Home</router-link>
            <div class="navbar-item has-dropdown is-hoverable">
              <a class="navbar-link">Services</a>
              <div class="navbar-dropdown">
                <router-link to="/design" class="navbar-item">Design</router-link>
                <router-link to="/manufacturing" class="navbar-item">Manufacturing</router-link>
                <router-link to="/machining" class="navbar-item">Machining</router-link>
                <router-link to="/maintenance" class="navbar-item">Maintenance</router-link>
                <router-link to="/installation" class="navbar-item">Installation</router-link>
              </div>
            </div>
            <router-link class="navbar-item" to="/gallery">Gallery</router-link>
            <router-link class="navbar-item" to="/about">About</router-link>
            <router-link class="navbar-item" to="/contact">Contact</router-link>
          </div>
        </div>
      </nav>
    </div>

    <div class="page-content">
      <router-view />
    </div>

    <div class="foot">
      <div class="contact text-666" v-if="$route.path !== '/contact'">
        <div class="content-container">
          <div class="columns">
            <div class="column">
              For all enquiries contact <b>CFE Projects & Maintenance</b> today.
            </div>
            <div class="column is-3 right">
              <router-link to="/contact" class="button is-info width-100">
                <fa :icon="['fas', 'envelope']" class="margin-right" />
                Contact Us
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="socials">
        <div class="content-container">
          <a class="facebook" target="_blank" href="https://www.facebook.com/CFE-Projects-Maintenance-PTY-LTD-379913218738878/">
            <fa :icon="['fab', 'facebook-square']" />
          </a>
          <!-- <fa :icon="['fab', 'instagram-square']" /> -->
          <a class="linkedin" target="_blank" href="https://www.linkedin.com/company/cfe-projects-and-maintenance/">
            <fa :icon="['fab', 'linkedin']" />
          </a>
        </div>
      </div>
      <div class="bottom-bar">
        <div class="content-container">
          Copyright CFE Projects & Maintenance PTY LTD 2021 - All Rights Reserved
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      burgersOn: false,
      scrolledDown: false,
      scrollLimit: 150
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  mounted () {
    this.handleScroll()
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll (e) {
      this.scrolledDown = document.body.scrollTop > this.scrollLimit || document.documentElement.scrollTop > this.scrollLimit
    }
  }
}
</script>

<style lang="scss">
#app {
  .logo {
    max-height: 100px;
    margin: 10px 0;
    image-rendering: crisp-edges;
    transition: all 200ms;
  }

  .top-bar .content-container {
    height: 30px;
    overflow-y: hidden;
    transition: all 200ms;
  }

  .page-content {
    padding-top: 165px;
  }
}

#app.scrolled-down {
  .logo {
    max-height: 80px;
  }

  .top-bar .content-container {
    height: 0px;
  }
}

#app {
   .page-content {
    background: #fff;
    min-height: calc(100vh - 343px);
  }

  .top-bar {
    background-color: #3f3f3f;
    color: #fff;
    max-width: 0 auto;
    overflow: hidden;
    a { color: #fff; }
    a:hover { color: #dd9933; }
  }

  .nav-container {
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 100;
    border-bottom: solid 1px #eee;
  }

  .navbar {
    background-color: transparent;
  }

  .nav-container,
  .nav-container .navbar {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover, a.navbar-item.is-active, .navbar-link:focus, .navbar-link:focus-within, .navbar-link:hover, .navbar-link.is-active {
    color: #3b86b0;
  }

  .navbar-link:not(.is-arrowless)::after {
    border-color: #4a4a4a;
  }

  .foot {
    .contact {
      border-top: 1px solid #eee;
      padding: 40px 0;
      background-color: #f7f7f7;
      margin-top: 40px;
    }

    .socials {
      text-align: center;
      padding: 30px 0;
      background-color: #CCCCCC;

      font-size: 2em;

      a {
        margin: 0 20px;
      }

      a:hover {
        opacity: 0.75;
      }

      .facebook {
        color: #415A93;
      }

      .linkedin {
        color: #5176AF;
      }
    }

    .bottom-bar {
      background-color: #222222;
      color: #ccc;
      font-size: 12px;;
      padding: 10px 0;
    }
  }
}

#app .nav-container {
  background-color: #fff;
}

#app.scrolled-down .nav-container {
  background-color: #f7f7f7;
  -webkit-box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
  box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
}

@media only screen and (max-width: 510px) {
  .top-bar .icons { display: none;}
}

@media only screen and (max-width: 420px) {
  .top-bar .address { display: none;}
}

@media only screen and (max-width: 200px) {
  .top-bar .phone { display: none;}
}

</style>
