<template>
  <div class="light-box">
    <cool-light-box
      :items="images"
      :index="index"
      @close="$emit('close')"
      :useZoomBar="true"
      :enableScrollLock="false"
    />

  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
export default {
  components: { CoolLightBox },
  props: ['images', 'index']
}
</script>
