import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '/', component: () => import('@/views/Home.vue') },
  { path: '/gallery', component: () => import('@/views/Gallery.vue') },
  { path: '/about', component: () => import('@/views/About.vue') },
  { path: '/contact', component: () => import('@/views/Contact.vue') },

  { path: '/design', component: () => import('@/views/Design.vue') },
  { path: '/manufacturing', component: () => import('@/views/Manufacturing.vue') },
  { path: '/machining', component: () => import('@/views/Machining.vue') },
  { path: '/maintenance', component: () => import('@/views/Maintenance.vue') },
  { path: '/installation', component: () => import('@/views/Installation.vue') }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const title = to.fullPath.trim().substring(1)
  const arr = []
  if (title.length && title !== 'home') arr.push(title.toLocaleUpperCase())
  arr.push('CFE Projects & Maintenance')
  document.title = arr.join(' - ')
  next()
})

export default router
